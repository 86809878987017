import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { MemoryRouter } from 'react-router-dom';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiButtonLink } from '../src';
import { UiIcon } from '@uireact/icons';
import { UiSpacing } from '@uireact/foundation';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "uibuttonlink"
    }}>{`UiButtonLink`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/text/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Component to render a wrapper around links to provide styling, you have to provide the link element, this was done so we could support
multiple use cases Link from react router, or Link from next JS or any other router you are using.`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/text`}</p>
    </blockquote>
    <h2 {...{
      "id": "uibuttonlink-1"
    }}>{`UiButtonLink`}</h2>
    <Playground __position={1} __code={'<UiButtonLink>\n  <a href=\"https://uireact.io/\">Some text</a>\n</UiButtonLink>'} __scope={{
      props,
      DefaultLayout,
      Props,
      MemoryRouter,
      packageJson,
      Playground,
      UiButtonLink,
      UiIcon,
      UiSpacing,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiButtonLink mdxType="UiButtonLink">
    <a href="https://uireact.io/">Some text</a>
  </UiButtonLink>
    </Playground>
    <h2 {...{
      "id": "uibuttonlink---with-options"
    }}>{`UiButtonLink - with options`}</h2>
    <Playground __position={2} __code={'<UiButtonLink\n  fullWidth\n  inverseTextColoration\n  size=\"xlarge\"\n  fontStyle=\"italic\"\n>\n  <UiSpacing padding={{ block: \'four\' }}>\n    <a href=\"https://uireact.io/\">\n      Some text <UiIcon icon=\"Link\" inverseColoration />{\' \'}\n    </a>\n  </UiSpacing>\n</UiButtonLink>'} __scope={{
      props,
      DefaultLayout,
      Props,
      MemoryRouter,
      packageJson,
      Playground,
      UiButtonLink,
      UiIcon,
      UiSpacing,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiButtonLink fullWidth inverseTextColoration size="xlarge" fontStyle="italic" mdxType="UiButtonLink">
    <UiSpacing padding={{
          block: 'four'
        }} mdxType="UiSpacing">
      <a href="https://uireact.io/">
        Some text <UiIcon icon="Link" inverseColoration mdxType="UiIcon" />{' '}
      </a>
    </UiSpacing>
  </UiButtonLink>
    </Playground>
    <h2 {...{
      "id": "uibuttonlink-with-default-coloration"
    }}>{`UiButtonLink with default coloration`}</h2>
    <Playground __position={3} __code={'<UiButtonLink coloration=\"dark\">\n  <a href=\"https://uireact.io/\">Will always render text as DARK coloration</a>\n</UiButtonLink>\n<UiButtonLink coloration=\"light\">\n  <a href=\"https://uireact.io/\">\n    Will always render text as LIGHT coloration\n  </a>\n</UiButtonLink>'} __scope={{
      props,
      DefaultLayout,
      Props,
      MemoryRouter,
      packageJson,
      Playground,
      UiButtonLink,
      UiIcon,
      UiSpacing,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiButtonLink coloration="dark" mdxType="UiButtonLink">
    <a href="https://uireact.io/">Will always render text as DARK coloration</a>
  </UiButtonLink>
  <UiButtonLink coloration="light" mdxType="UiButtonLink">
    <a href="https://uireact.io/">Will always render text as LIGHT coloration</a>
  </UiButtonLink>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      